import React, { useRef, useEffect } from "react"
import styles from "./index.module.scss"
import Img from "gatsby-image"
import { useSelector } from "react-redux"
import BackgroundImage from "gatsby-background-image"
import Slider from "react-slick"
import { initVimeoPlayer } from "../../utils"
import CTABtn from "../../Button/CTA"
import Explore from "../Home/Explore"
import Join from "../Home/Join"

export default function ArticleOne({ sanity }) {
  const isChinese = useSelector(state => state.lang.locale) === "tc"
  return (
    <div>
      <SectionIntro isChinese={isChinese} />
      <SectionTwo isChinese={isChinese} data={sanity} />
      <SectionThree isChinese={isChinese} />
      <SectionFour isChinese={isChinese} />
      <SectionFive isChinese={isChinese} data={sanity} />
      <SectionSix isChinese={isChinese} data={sanity} />
      <SectionSeven isChinese={isChinese} data={sanity} />
      <SectionEight data={sanity} />
      <SectionNine isChinese={isChinese}/>
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}

function SectionIntro() {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/410547544/e08465d350",
        mobileVid: "https://vimeo.com/410549035/f2e328f84c",
      })
  }, [])

  return (
    <section>
      <header
        className={`${styles.header} relative overflow-hidden flex justify-start`}
      >
        <div className="bg-container bg-black absolute w-full h-full top-0 left-0 -z-10">
          <div
            ref={video}
            className="video-iframe-container absolute top-1/2 left-1/2 w-full h-full transform -translate-x-1/2 -translate-y-1/2"
          ></div>
        </div>
      </header>
    </section>
  )
}

function SectionTwo({ isChinese, data }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <h2 className="quotes md:text-5xl bold-font w-8/12 md:w-full mb-10 md:mb-20">
              {isChinese
                ? "BMW 全新品牌形象"
                : "The Progressive New BMW Logo Design"}
            </h2>
            <p className="large-text mb-20 md:mb-12">
              {isChinese
                ? "「這不僅僅是設計上的更新，更代表BMW往後的理念—成為更加側重用戶關係的品牌。新標誌代表著更大的開放性及清晰度。 透過這個新標誌，拉近我們與客人的距離，期待更多客人加入BMW世界。」"
                : "“BMW becomes a relationship brand. The new communication logo radiates openness and clarity. With this new transparent variant, we want to invite our customers more than ever to become a part of the BMW World.”"}
            </p>
            <p className="large-text light-font mb-20 md:mb-12">
              {isChinese
                ? "此外，我們的新品牌設計旨在應對數碼化帶來的挑戰和機遇。BMW 全新標誌在視覺上變得更 有約束力和靈活性，於未來將會在線下和線上提供各種通訊聯繫點。全新設計亦象徵著品牌為未來帶 來的駕駛樂趣意義和相關性。"
                : "In addition, our new brand design is geared to the challenges and opportunities of Digitization for brands. With visual restraint and graphic, we are equipping ourselves flexibly for the wide variety of contact points in communication at which BMW will show its presence online and offline in the future. The additional communication logo symbolizes the significance and relevance of the brand for mobility and driving pleasure in the future."}
            </p>
            <p className="text-4xl md:text-3xl light-font xxl:mb-56 mb-40">
              {isChinese
                ? "— BMW 客戶與品牌高級副總裁 Jens Thiemer"
                : "- Jens Thiemer, Senior Vice President Customer & Brand BMW"}
            </p>
            <div className="flex md:flex-col md:-mx-12">
              <div className="w-1/2 md:w-full">
                <Img fluid={data.articleimg.childImageSharp.fluid} />
              </div>
              <div className="w-1/2 md:w-full mt-48 md:mt-12">
                <Img fluid={data.articleimg2.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionThree({ isChinese }) {
  return (
    <section className="py-40 md:py-20 bg-brand-light-grey">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <h3 className="quotes quotes--sm md:text-4xl bold-font text-right md:text-left md:mb-20">
                  {isChinese
                    ? "BMW 全新品牌形象不止標誌革新，更旨在締造更理想用家生活。"
                    : "BMW has a new Brand Identity."}
                </h3>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0">
                <p className="large-text light-font">
                  {isChinese
                    ? "透明簡約的設計，象徵我們在未來征途上採取開放態度，與您同編製前瞻性的駕駛藍圖。從內容和字體開始，新品牌設計 並不受規則所限，能夠應用於不同印刷，空間設計，以及我們的品牌行為上。"
                    : " With more than just a design update, we shift in our attitude and become more transparent in the way in which we take responsibility, forge new ways forward and secure our independence. Beginning with our content and character, the use of our new Brand Design will be guided by overarching principles rather than rules, which can be applied across print, spatial design, digital as well as in our Brand Behaviour."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFour({ isChinese }) {
  const video = useRef(null)

  useEffect(() => {
    video.current &&
      initVimeoPlayer({
        selector: video.current,
        desktopVid: "https://vimeo.com/410547544/e08465d350",
        mobileVid: "https://vimeo.com/410549035/f2e328f84c",
      })
  }, [])

  return (
    <section className="pt-40 md:pt-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="px-48 mb-32 md:px-0">
              <div ref={video}></div>
            </div>

            <div className="w-8/12 md:w-full">
              <p className="large-text mb-20">
                {isChinese
                  ? "新標誌內部對角中的藍色與白色更為純淨清澈; 外圈黑色圓環部分以透明形式呈現，使新標誌展現出 愈加簡約、開放的扁平化設計理念。"
                  : "The new logo keeps the checkered blue-and-white pattern at its center; while the thick outer ring in the circular logo went from a solid black to transparent."}
              </p>
              <p className="text-4xl md:text-3xl light-font">
                {isChinese
                  ? "BMW 傳達的視覺風格更適合現今的數碼時代。"
                  : "The design was also made simpler and flatter, which made it look more three-dimensional. The BMW brand now delivers on the expectations and visual style of today and is better-suited to the digital age."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFive({ isChinese, data }) {
  return (
    <section className="py-40 md:py-20">
      <Img fluid={data.articleimg3.childImageSharp.fluid} />
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full xxl:px-40 px-20 md:px-0">
            <div className="text-center">
              <div className="flex justify-center">
                <hr className="w-64 md:w-full h-1 bg-black mt-32 mb-20 md:hidden" />
              </div>
              <h3 className="quotes--sm md:text-4xl bold-font text-center leading-relaxed md:leading-normal md:mt-20">
                {isChinese
                  ? "/// M 徽章變得更扁平化，象徵著「世界上最有力量的字母」。"
                  : "The ///M badge is now flat, a 2D interpretation of “The Most Powerful Letter In The World”."}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSix({ isChinese, data }) {
  return (
    <section className="pt-40 md:pt-20">
      {/* <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12"> */}
      <div className="flex md:flex-col">
        <div className="w-1/2 md:w-full">
          <Img fluid={data.articleimg4.childImageSharp.fluid} />
        </div>
        <div className="w-1/2 md:w-full flex">
          <div className="self-center px-20 md:py-20 md:px-12">
            <p className="large-text light-font leading-relaxed">
              {isChinese
                ? "BMW i 車型將以煥然一新的姿態繼續成為未來的電動車和混能電動車的一部分。"
                : "The i sub-brand continues down the same path with a refreshed look which will be part of the future electric and plug-in hybrid family."}
            </p>
          </div>
        </div>
      </div>
      <div className="flex md:flex-col">
        <div className="w-1/2 md:w-full flex bg-brand-grey md:order-2">
          <div className="self-center px-20 md:py-20 md:px-12">
            <p className="large-text light-font text-white leading-relaxed">
              {isChinese
                ? "全新品牌形象為未來數碼化做好準備，關鍵部分包括標誌，新字體和視覺語言。新字體稱為 BMW Type Next 的字體代表著開放，前衛，並代表著我們的立場。視覺語言延伸到攝影和視覺領域，BMW 將以人為本，邁向更具有個性化的品牌形象。"
                : "The new identity is an attempt to prepare for a more digital future. The key parts include the logo, the new typeface and visual language. The typeface, called BMW Type Next, is “open, edgy and recognizes what we stand for”. The visual language extends to photography and visuals, where we will be more people-focused, with a more personalized brand identity."}
            </p>
          </div>
        </div>
        <div className="w-1/2 md:w-full md:order-1">
          <Img fluid={data.articleimg5.childImageSharp.fluid} />
        </div>
      </div>
      {/* </div>
        </div>
      </div> */}
    </section>
  )
}

function SectionSeven({ isChinese, data }) {
  return (
    <section className="pb-40 md:pb-20">
      <div className={`bg-container w-full h-50vh`}>
        <BackgroundImage
          Tag="div"
          className="w-full h-full"
          fluid={data.articleimg6.childImageSharp.fluid}
          backgroundColor={`#040e18`}
          loading="eager"
          critical={true}
        />
      </div>
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="text-center mt-40">
              <p className="large-text text-center leading-relaxed mb-20">
                {isChinese
                  ? '「 BMW Concept i4 的設計靈感源於其出色的比例、富有表現力的特徵以及對細節的高度關注。 」 '
                  : '"The design of the BMW Concept i4 inspires with its fantastic proportions, expressive character and, of course, great attention to detail."'}
              </p>
              <p className="large-text light-font text-center leading-relaxed">
                {isChinese
                  ? "— BMW 設計主管 Domagoj Dukec"
                  : "Domagoj Dukec, Head of BMW Design"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionEight({ data }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
  }
  return (
    <section className="py-10 xxl:py-20 md:py-0 article-slider article-slider--logo bg-black md:bg-transparent overflow-hidden">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-8/12 md:w-full">
            <Slider {...settings}>
              <div className="slide h-full px-12 md:px-0">
                <Img fluid={data.articleimg7.childImageSharp.fluid} />
              </div>
              <div className="slide h-full px-12 md:px-0">
                <Img fluid={data.articleimg8.childImageSharp.fluid} />
              </div>
              <div className="slide h-full px-12 md:px-0">
                <Img fluid={data.articleimg9.childImageSharp.fluid} />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionNine({ isChinese }) {
  return (
    <section className="py-40 my-40 md:py-20 md:my-20 bg-brand-light-grey">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex justify-center md:flex-col">
              <div className="w-1/2 md:w-full">
                <p className="large-text leading-relaxed mb-20">
                  {isChinese
                    ? "這象徵著我們新時代的開始；BMW成為更加側重用戶關係的品牌，豐富您的旅程並駕馭更多未來挑戰。"
                    : "This is the beginning of a new era for us; BMW is becoming a relationship brand, enriching your journey and empowering your future."}
                </p>
                <div className="flex justify-center">
                  <hr className="w-40 md:w-full h-1 bg-black md:hidden" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
